/*
 * Bar Charts for the Data Insights page
 * See https://app.asana.com/0/1200096737277303/1200360493777654/f
 */

import React from 'react';
import PropTypes from 'prop-types';
import {BarChart, Bar, Tooltip, XAxis, YAxis, ResponsiveContainer, LabelList} from 'recharts';

const renderCustomizedLabel = props => {
    const {x, y, value, index} = props;
    const radius = 10;

    return (
        <g>
            <text
                dominantBaseline='middle'
                fill='#000'
                textAnchor='left' x={x - 30}
                y={y - radius}>
                {index + 1}
            </text>
            <text
                dominantBaseline='middle'
                fill='#000'
                textAnchor='left' x={x}
                y={y - radius}>
                {value}
            </text>
        </g>
    );
};

const DataInsightBarChart = ({data, title}) =>
    <>
        <p><strong>{title}</strong></p>
        {data.length > 0 &&
            <div className='data-insight-chart'>
                <ResponsiveContainer height={275} width='99%'>
                    <BarChart barSize={14}
                        data={data}
                        layout='vertical'
                        margin={{top: 5, right: 5, bottom: 5, left: 30}}>
                        <Tooltip/>
                        <XAxis hide type='number'/>
                        <YAxis dataKey='name' hide type='category'/>
                        <Bar background={{fill: '#f0f6f6'}} dataKey='value' fill='#43acbd' name='Responses'>
                            <LabelList content={renderCustomizedLabel} dataKey='name' position='insideLeft'/>
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        }
        {data.length === 0 &&
            <p>No data available</p>
        }
    </>;

DataInsightBarChart.propTypes = {
    data: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired
};

export default DataInsightBarChart;