import CountUp from 'react-countup';
import React from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';

class Count extends React.Component {
    state = {
        didViewCountUp: false
    };

    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({didViewCountUp: true});
        }
    };

    /* eslint-disable no-undefined */
    render() {
        return (
            <VisibilitySensor delayedCall offset={{
                top:
            10
            }} onChange={this.onVisibilityChange}>
                <CountUp duration={3} end={this.state.didViewCountUp ? this.props.end : 0}
                    formattingFn={value => value.toLocaleString(undefined, {maximumFractionDigits: 2})}
                    start={0}/>
            </VisibilitySensor>
        );
    }
}

Count.propTypes = {
    end: PropTypes.number.isRequired
};

export default Count;