import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {notify} from 'react-notify-toast';

export default class FloatCallout extends PureComponent {
    componentDidMount() {
        notify.show(this.props.text, this.props.type, this.props.timeout);
    }

    render() {
        return null;
    }
}

FloatCallout.propTypes = {
    text: PropTypes.string.isRequired,
    timeout: PropTypes.string,
    type: PropTypes.oneOf(['error', 'success', 'warning']).isRequired
};