import React, {useState} from 'react';
import posed from 'react-pose';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import {tween} from 'popmotion';

const Score = posed.div({
    initial: {
        scale: 1,
        y: '100%',
        left: '50%',
        x: '-50%'
    },
    end: {
        y: '10vh',
        x: '-105%',
        scale: 0.6,
        transition: ({duration}) => ({duration})
    }
});

const PeopleHelped = posed.div({
    initial: {
        scale: 0.2,
        x: '-50%',
        left: '50%',
        y: '22vh',
        opacity: 0
    },
    end: {
        scale: 1,
        opacity: 1,
        delay: 2200,
        transition: props => tween({...props, duration: 500})
    }
});

const Reward = props => {
    const {dataPoints, peopleHelped} = props;
    const [startScoreAnimation, setScoreAnimation] = useState(false);

    return (
        <div className='animate'>
            <Score
                className='score'
                duration={1000}
                initialPose='initial'
                pose={startScoreAnimation && ['end']}
            >
                <div className='circle'>
                    <span>
                        <strong>
                            <CountUp end={dataPoints}
                                onEnd={() => setScoreAnimation(true)}
                            />
                        </strong>
            data points
                    </span>
                </div>
            </Score>
            <PeopleHelped
                className='people-helped'
                duration={1000}
                initialPose='initial'
                pose={['end']}
            >
                <div className='circle'>
                    <span>
                        <strong>
                            <CountUp
                                delay={2}
                                end={peopleHelped}
                                separator=','
                            />
                        </strong>
              people helped
                    </span>
                </div>
            </PeopleHelped>
        </div>
    );
};

Reward.propTypes = {
    dataPoints: PropTypes.number.isRequired,
    levelCompleted: PropTypes.number.isRequired,
    peopleHelped: PropTypes.number.isRequired,
    surveyType: PropTypes.string.isRequired
};

export default Reward;