/* eslint-disable global-require */
export const severityLevels = {
    PAIN_LEVEL_0: {
        name: 'Worst immaginable health state',
        url: require('./pain_level_5.png'),
        greyUrl: require('./pain_level_grey_5.png')
    },
    PAIN_LEVEL_1: {
        name: 'Hurts Whole Lot',
        url: require('./pain_level_4.png'),
        greyUrl: require('./pain_level_grey_4.png')
    },
    PAIN_LEVEL_2: {
        name: 'Hurts Whole Lot',
        url: require('./pain_level_4.png'),
        greyUrl: require('./pain_level_grey_4.png')
    },
    PAIN_LEVEL_3: {
        name: 'Hurts Whole Lot',
        url: require('./pain_level_4.png'),
        greyUrl: require('./pain_level_grey_4.png')
    },
    PAIN_LEVEL_4: {
        name: 'Hurts Even More',
        url: require('./pain_level_3.png'),
        greyUrl: require('./pain_level_grey_3.png')
    },
    PAIN_LEVEL_5: {
        name: 'Hurts Even More',
        url: require('./pain_level_3.png'),
        greyUrl: require('./pain_level_grey_3.png')
    },
    PAIN_LEVEL_6: {
        name: 'Hurts Little More',
        url: require('./pain_level_2.png'),
        greyUrl: require('./pain_level_grey_2.png')
    },
    PAIN_LEVEL_7: {
        name: 'Hurts Little Bit',
        url: require('./pain_level_1.png'),
        greyUrl: require('./pain_level_grey_1.png')
    },
    PAIN_LEVEL_8: {
        name: 'Hurts Little Bit',
        url: require('./pain_level_1.png'),
        greyUrl: require('./pain_level_grey_1.png')
    },
    PAIN_LEVEL_9: {
        name: 'Hurts Little Bit',
        url: require('./pain_level_1.png'),
        greyUrl: require('./pain_level_grey_1.png')
    },
    PAIN_LEVEL_10: {
        name: 'Best immaginable health state',
        url: require('./pain_level_0.png'),
        greyUrl: require('./pain_level_grey_0.png')
    }
};

export const employmentImpactLevels = {
    PAIN_LEVEL_0: {
        name: 'Symptoms had no effect on my work',
        url: require('./pain_level_0.png'),
        greyUrl: require('./pain_level_grey_0.png')
    },
    PAIN_LEVEL_1: {
        name: 'Impacts Little Bit',
        url: require('./pain_level_1.png'),
        greyUrl: require('./pain_level_grey_1.png')
    },
    PAIN_LEVEL_2: {
        name: 'Impacts Little Bit',
        url: require('./pain_level_1.png'),
        greyUrl: require('./pain_level_grey_1.png')
    },
    PAIN_LEVEL_3: {
        name: 'Impacts Little Bit',
        url: require('./pain_level_1.png'),
        greyUrl: require('./pain_level_grey_1.png')
    },
    PAIN_LEVEL_4: {
        name: 'Impacts Little More',
        url: require('./pain_level_2.png'),
        greyUrl: require('./pain_level_grey_2.png')
    },
    PAIN_LEVEL_5: {
        name: 'Impacts Even More',
        url: require('./pain_level_3.png'),
        greyUrl: require('./pain_level_grey_3.png')
    },
    PAIN_LEVEL_6: {
        name: 'Impacts Even More',
        url: require('./pain_level_3.png'),
        greyUrl: require('./pain_level_grey_3.png')
    },
    PAIN_LEVEL_7: {
        name: 'Impacts Whole Lot',
        url: require('./pain_level_4.png'),
        greyUrl: require('./pain_level_grey_4.png')
    },
    PAIN_LEVEL_8: {
        name: 'Impacts Whole Lot',
        url: require('./pain_level_4.png'),
        greyUrl: require('./pain_level_grey_4.png')
    },
    PAIN_LEVEL_9: {
        name: 'Impacts Whole Lot',
        url: require('./pain_level_4.png'),
        greyUrl: require('./pain_level_grey_4.png')
    },
    PAIN_LEVEL_10: {
        name: 'Could not do any work due to symptoms',
        url: require('./pain_level_5.png'),
        greyUrl: require('./pain_level_grey_5.png')
    }
};