import React from 'react';
import PropTypes from 'prop-types';
import TagInput from './TagInput';

class TagWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {tags: props.values};
    }

    render() {
        const {attribute, description, header, tagInputLabel, tagViewLabel, errorMessage} = this.props;
        const {tags} = this.state;
        const containerClasses = ['cell', 'small-3', 'medium-3'];
        let messageEl = null;

        if (errorMessage && errorMessage.length > 0) {
            containerClasses.push('field-with-errors');
            messageEl = <label className='message1'>{errorMessage}</label>;
        }

        return (
            <div className='pain-slider-main-container grid-container'>
                <div className='grid-x pain-slider-inner-container'>
                    <input name={attribute} type='hidden' value=''/>
                    { tags.map(({value}) => <input key={value} name={attribute} type='hidden' value={value}/>) }
                    <div className='cell medium-5 large-4 small-order-1 pain-slider-cell-left grid-y grid-container'>
                        <div className='cell small-12'>
                            <h2>{ header }</h2>
                            <h3 className='pain-severity-description' dangerouslySetInnerHTML={{__html: description}}/>
                            <p>Type your own descriptor or use the suggestions in the dropdown.
                                Your choices will populate below. You can add or remove as many as you’d like.
                            </p>
                        </div>
                    </div>
                    <div className='cell medium-1 small-order-2 divider-curved'/>
                    <div className='cell medium-6 large-7 small-order-3 pain-slider-cell-right'>
                        <div className='cell small-1 medium-1'/>
                        <div className='cell small-7 medium-7 pain-slider-container'>
                            <div className='tag-wrapper-box'>
                                <p>
                                    <b className='hide-for-small-only'>{tagViewLabel}</b>
                                </p>
                            </div>
                        </div>
                        <div className={containerClasses.join(' ')}>
                            <TagInput
                                errorMessage='lorem ipsum dolor sit amet'
                                placeholder={tagInputLabel}
                                suggestions={this.props.suggestions}
                                tags={this.state.tags}
                                tagsCallback={values => this.setState({tags: values})}
                            />
                            {messageEl}
                        </div>
                        <div className='cell auto'/>
                    </div>
                </div>
            </div>
        );
    }
}

TagWrapper.defaultProps = {
    values: [],
    errorMessage: null
};

TagWrapper.propTypes = {
    attribute: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    errorMessage: PropTypes.string,
    header: PropTypes.string.isRequired,
    suggestions: PropTypes.arrayOf(PropTypes.shape({label: PropTypes.string, value: PropTypes.string})).isRequired,
    tagInputLabel: PropTypes.string,
    tagViewLabel: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(PropTypes.shape({label: PropTypes.string, value: PropTypes.string}))
};

export default TagWrapper;