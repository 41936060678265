import React from 'react';
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import PropTypes from 'prop-types';

const DataPointsBreakdownGraph = props =>
    <ResponsiveContainer height={250} width='100%'>
        <BarChart data={props.data}>
            <CartesianGrid stroke='#eeeeee'/>
            <XAxis dataKey='date' tick={{fontSize: 12}}/>
            <YAxis tick={{fontSize: 12}}/>
            <Tooltip/>
            <Legend/>
            <Bar dataKey='points' fill='#1976d2' stackId='a'/>
        </BarChart>
    </ResponsiveContainer>;

DataPointsBreakdownGraph.propTypes = {
    data: PropTypes.array.isRequired
};

export default DataPointsBreakdownGraph;