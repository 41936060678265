import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Particles, {initParticlesEngine} from '@tsparticles/react';
import {loadFull} from 'tsparticles';

/* eslint-disable camelcase */
const particlesOptions = number => ({
    particles: {
        number: {
            value: number,
            density: {
                enable: true,
                area: 375
            }
        },
        color: {
            value: '#561a6b'
        },
        shape: {
            type: 'circle',
            stroke: {
                width: 0,
                color: '#561a6b'
            },
            polygon: {
                sides: 4
            },
            image: {
                src: 'img/github.svg',
                width: 100,
                height: 100
            }
        },
        opacity: {
            value: {min: 0.05, max: 0.25},
            animation: {
                enable: false,
                speed: 1,
                startValue: 'random',
                sync: false
            }
        },
        size: {
            value: {min: 1, max: 28.05971106514665},
            animation: {
                enable: false,
                speed: 40,
                startValue: 'random',
                sync: false
            }
        },
        links: {
            enable: true,
            distance: 150,
            color: '#561a6b',
            opacity: 0.4,
            width: 1
        },
        move: {
            enable: true,
            speed: 2,
            direction: 'none',
            random: false,
            straight: false,
            out_mode: 'out',
            bounce: false,
            attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
            }
        }
    },
    interactivity: {
        detectsOn: 'canvas',
        events: {
            onHover: {
                enable: false,
                mode: 'repulse'
            },
            onClick: {
                enable: false,
                mode: 'push'
            },
            resize: true
        },
        modes: {
            grab: {
                distance: 400,
                links: {
                    opacity: 1
                }
            },
            bubble: {
                distance: 400,
                size: 40,
                duration: 2
            },
            repulse: {
                distance: 200,
                duration: 0.4
            },
            push: {
                quantity: 4
            },
            remove: {
                quantity: 2
            }
        }
    },
    fullScreen: {
        enable: false
    },
    detectRetina: false
});
/* eslint-enable camelcase */

const ParticleWrapper = ({number}) => {
    const [init, setInit] = useState(false);
    const options = particlesOptions(number);

    useEffect(() => {
        initParticlesEngine(async engine => {
            /*
             *  you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
             *  this loads the tsparticles package bundle, it's the easiest method for getting everything ready
             *  starting from v2 you can add only the features you need reducing the bundle size
             */
            await loadFull(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    return init && <Particles
        className='height-100'
        id='tsparticles' options={options}
    />;
};

ParticleWrapper.defautProps = {
    interactivity: true
};

ParticleWrapper.propTypes = {
    interactivity: PropTypes.bool,
    number: PropTypes.number.isRequired
};

export default ParticleWrapper;